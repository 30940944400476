$(document).ready(function(){
	// HTML markup implementation, No-overlap mode
	$( '#menu' ).multilevelpushmenu({
		containersToPush: [$( '#pushobj' )],
        mode: 'cover',
        backItemIcon: 'kpn-icon kpn-chevron-left',
		groupIcon: 'kpn-icon kpn-chevron-right',
		durationSlideOut: 200,
		durationSlideDown: 300,
		durationTransition: 200,
	});
});